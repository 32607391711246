var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("a-input", {
            attrs: { placeholder: "输入标题进行搜索" },
            on: { pressEnter: _vm.search, change: _vm.valueNull },
            model: {
              value: _vm.searchInpt,
              callback: function($$v) {
                _vm.searchInpt = $$v
              },
              expression: "searchInpt"
            }
          }),
          _c("button", { staticClass: "search", on: { click: _vm.search } }, [
            _vm._v("搜索")
          ]),
          _c(
            "button",
            {
              staticClass: "add",
              staticStyle: { width: "120px" },
              on: {
                click: function($event) {
                  return _vm.openModal()
                }
              }
            },
            [_vm._v("新增分类")]
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c("a-table", {
            attrs: {
              columns: _vm.list,
              "data-source": _vm.data,
              rowKey: "id",
              pagination: _vm.pagination
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "name",
                fn: function(text) {
                  return _c("a", {}, [_vm._v(_vm._s(text))])
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a",
                        {
                          staticStyle: { "margin-right": "5px" },
                          on: {
                            click: function($event) {
                              return _vm.openModal(row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "确定要删除此项么？",
                            "ok-text": "确认",
                            "cancel-text": "取消"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row.id)
                            }
                          }
                        },
                        [
                          _c("a", { staticStyle: { "margin-left": "5px" } }, [
                            _vm._v("删除")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.innerTitle,
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal" },
            [
              _c("p", [_vm._v("标题")]),
              _c("a-input", {
                attrs: { placeholder: "" },
                model: {
                  value: _vm.categoryName,
                  callback: function($$v) {
                    _vm.categoryName = $$v
                  },
                  expression: "categoryName"
                }
              }),
              _c("p", [_vm._v("出现位置")]),
              _c(
                "a-select",
                {
                  staticStyle: { width: "250px", margin: "5px" },
                  attrs: { allowClear: "", placeholder: "数据源" },
                  on: { change: _vm.sectionChangeSelect },
                  model: {
                    value: _vm.positionStatus,
                    callback: function($$v) {
                      _vm.positionStatus = $$v
                    },
                    expression: "positionStatus"
                  }
                },
                [
                  _c("a-select-option", { attrs: { value: 1 } }, [
                    _vm._v("主页")
                  ]),
                  _c("a-select-option", { attrs: { value: 2 } }, [
                    _vm._v("钱币学堂首页")
                  ])
                ],
                1
              ),
              _c("p", [_vm._v("排序")]),
              _c("a-input", {
                attrs: { placeholder: "" },
                model: {
                  value: _vm.sort,
                  callback: function($$v) {
                    _vm.sort = $$v
                  },
                  expression: "sort"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }