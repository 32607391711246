<template>
  <div>
    <div class="top">
      <a-input
        placeholder="输入标题进行搜索"
        v-model="searchInpt"
        @pressEnter="search"
        @change="valueNull"
      />
      <button class="search" @click="search">搜索</button>
      <button class="add" style="width: 120px;" @click="openModal()">新增分类</button>
    </div>
    <div>
      <a-table
        :columns="list"
        :data-source="data"
        rowKey="id"
        @change="changePage"
        :pagination="pagination"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <div slot="setup" slot-scope="row">
          <a @click="openModal(row)" style="margin-right: 5px">编辑</a>
          <a-popconfirm
            title="确定要删除此项么？"
            ok-text="确认"
            cancel-text="取消"
            @confirm="remove(row.id)"
          >
            <a style="margin-left: 5px">删除</a>
          </a-popconfirm>
        </div>
      </a-table>
    </div>
    <a-modal
      v-model="visible"
      :title="innerTitle"
      ok-text="确认"
      cancel-text="取消"
      @cancel="resetForms"
      @ok="hideModal"
    >
      <div class="modal">
        
        <p>标题</p>
        <a-input placeholder="" v-model="categoryName" />

        <p>出现位置</p>
        <a-select @change="sectionChangeSelect" allowClear style="width:250px;margin:5px;"  v-model="positionStatus" placeholder='数据源'>
            <a-select-option :value='1'>主页</a-select-option>
            <a-select-option :value='2'>钱币学堂首页</a-select-option>
        </a-select>

        <p>排序</p>
        <a-input placeholder="" v-model="sort" />

      </div>
    </a-modal>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      editorOption: {
        placeholder: "编辑类别名称",
      },
      innerTitle: "123",
      visible: false,
      categoryName: "",
      sort: "",
      positionStatus: "",
      openType: "",
      type: "",
      list: [
        {
          title: "标题",
          align: "center",
          dataIndex: "categoryName",
        },

        {title:'出现位置',dataIndex:'positionStatus',key:'positionStatus',
            customRender:(text)=>{
                const inner = text
                if(inner == 1){
                    return <span style="color:green">首页</span>
                }
                if(inner == 2){
                    return <span>钱币学堂页</span>
                }
            }
        },

        {
          title: "排序",
          dataIndex: "sort",
          align: "center",
        },

        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      pagination: {
        pageSize: 8,
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      params: { page: 1, pageSize: 8, title: "" },
      id: "",
      searchInpt: "",
    };
  },
  methods: {
    getList(params) {
      this.axios("/dq_admin/bbsArticleCategory/list", {
        params: params,
      }).then((res) => {
        let list = res.data.records;
        this.data = list;
        this.pagination.total = res.data.total;
      });
    },
    changePage(page) {
      const { current, pageSize } = page;

      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList(this.params);
    },
    search(e) {
      if (this.searchInpt == "") {
        this.$message.warning("请输入标题再进行搜索");
      } else {
        this.params.page = 1;
        this.params.title = this.searchInpt;
        this.getList(this.params);
        this.pagination.current = 1;
      }
    },
    
    valueNull() {
      if (this.searchInpt == "") {
        this.params.title = this.searchInpt;
        this.getList(this.params);
        this.pagination.current = 1;
      }
    },
    openModal(row) {
      if (row) {
        this.visible = true;
        this.innerTitle = "编辑";
        this.id = row.id;
        this.axios("/dq_admin/bbsArticleCategory/getById", {
          params: { id: row.id },
        }).then((res) => {
          this.content = res.data.content;
        });
        this.positionStatus = row.positionStatus;
        this.sort = row.sort;
        this.categoryName = row.categoryName;
        this.openType = 1;
        
      } else {
        this.openType = 2;
        this.sort = "";
        this.categoryName = "";
        this.positionStatus = "";
        this.visible = true;
        this.innerTitle = "新增分类";
      }
    },
    remove(id) {
      this.axios("/dq_admin/bbsArticleCategory/delById", {
        params: { id: id },
      }).then((res) => {
        this.getList(this.params);

        this.$message.success("删除成功");
      });
    },
    hideModal() {
      if (this.categoryName == "") {
        this.$message.warning("请输入类别标题");
      }  else {
        if (this.openType == 1) {
          this.axios
            .post("/dq_admin/bbsArticleCategory/addOrEdit", {
              categoryName: this.categoryName,
              positionStatus: this.positionStatus,
              sort: this.sort,
              type: 1,
              id: this.id,
            })
            .then((res) => {
              this.getList(this.params);
              this.visible = false;
              this.$message.success("修改成功");
            });
        } else {
          this.axios
            .post("/dq_admin/bbsArticleCategory/addOrEdit", {
              categoryName: this.categoryName,
              positionStatus: this.positionStatus,
              sort: this.sort,
              type: 1,
            })
            .then((res) => {
              this.getList(this.params);
              this.visible = false;
              this.$message.success("新增成功");
            });
        }
      }
    },
    resetForms() {},

    sectionChangeSelect(e){
      this.positionStatus = e
    },
  },
  mounted() {
    this.getList(this.params);
  },
};
</script>
<style lang="scss" scoped>
.top {
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    width: 65px;
    height: 28px;
    border: none;
    background: #1890ff;
    color: #ffffff;
    border-radius: 5px;
    margin-right: 25px;
    outline-style: none;
    cursor: pointer;
  }
}
.modal {
  input {
    margin-bottom: 5px;
  }
}
</style>